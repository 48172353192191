import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Environment } from 'src/environments/environment.interface';

@Injectable({ providedIn: 'root' })
export class EnvironmentLoaderService {
  envConfig: any;
  authConfig: any;

  constructor(private handler: HttpBackend) {}

  async loadConfig(envPath: string, configPath: string) {
    await this.loadEnvConfig(envPath).then(() =>
      this.loadAuthConfig(configPath)
    );
  }

  async loadEnvConfig(configPath: string): Promise<void> {
    this.envConfig = await lastValueFrom(
      new HttpClient(this.handler).get(configPath)
    );
  }

  async loadAuthConfig(configPath: string): Promise<void> {
    this.authConfig = await lastValueFrom(
      new HttpClient(this.handler).get(configPath)
    );
  }

  get environmentName() {
    if (!this.envConfig) throw 'env config not loaded error';
    const envObject = Object.values(this.envConfig).find((env: any) =>
      location.host.includes(env.host)
    );
    const envName: string = Object.keys(this.envConfig)[
      Object.values(this.envConfig).indexOf(envObject)
    ];
    return envName;
  }

  get environment(): any {
    const env: string = this.environmentName;
    const envConfig = this.envConfig[env];
    const typedEnvironment = environment as Environment;

    // If `apiEndpoint` exists in the Environment, use it to override the value passed in `envConfig`;
    return {
      ...envConfig,
      apiEndpoint: typedEnvironment.apiEndpoint ?? envConfig['apiEndpoint'],
    };
  }

  get baseUrl(): string {
    return this.environment.apiEndpoint;
  }

  get isProduction(): string {
    return this.environment.production;
  }

  get authEnvironmentName() {
    if (!this.authConfig) throw 'auth config not loaded error';
    const authObject = Object.values(this.authConfig).find((ac: any) =>
      ac.redirect_url.includes(location.host)
    );
    const envName: string = Object.keys(this.authConfig)[
      Object.values(this.authConfig).indexOf(authObject)
    ];
    return envName;
  }

  get config(): any {
    const env: string = this.authEnvironmentName;
    const authObject = this.authConfig[env];
    return {
      authority: authObject.authority,
      redirectUri: authObject.redirect_url,
      clientId: authObject.client_id,
      scope: authObject.scope
    };
  }
}
