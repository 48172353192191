<div class="modal-header">
  <div class="modal-title">Opening an individual account is fast and easy.</div>
  <app-ng-lib-button
    data-cy="landing-retrieve-button"
    type="button"
    icon="fa fa-times"
    iconPosition="right"
    [iconOnly]="true"
    (click)="activeModal.dismiss()"
  >
  </app-ng-lib-button>
</div>
<div class="modal-body">
  <p class="fw-bold">Application guidelines:</p>
  <ul>
    <li>The form must be completed in English.</li>
    <li>
      If you are submitting a document in a foreign-language (non-English),
      please also submit a corresponding version translated into English.
    </li>
    <li>
      Please submit color passport copy when prompted to provide your
      identification documents.
    </li>
    <li>You must be at least 18 years old.</li>
  </ul>

  <p class="fw-bold">
    You'll need to have these pieces of information ready before you get
    started:
  </p>
  <ul>
    <li>Your personal particulars.</li>
    <li>Residential address.</li>
    <li>Tax Identification Number ("TIN").</li>
    <li>Mobile phone and email address.</li>
    <li>Details of an external bank account in your name.</li>
    <li>
      Valid Passport and Proof of Address document dated within the last 3
      months.<br />
      <span class="small text-muted">
        (i.e. utility bill or bank statement)
      </span>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end w-100">
    <a
      data-cy="landing-start-button"
      routerLink="/form/individual/account"
      class="btn btn-primary"
    >
      <span>Let's Get Started</span>
    </a>
  </div>
</div>
