<nav class="banner">
  <div class="container">
    <div
      class="d-inline-flex align-items-end h-100 w-100 position-relative title-wrapper"
    >
      <div class="col-lg-6">
        <h1 class="title tw-light">
          Individual <span class="fw-bold">account application</span>.
        </h1>
      </div>
    </div>
    <figure class="background">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
      >
        <circle
          data-aos="fade-up"
          data-aos-delay="200"
          cx="120%"
          cy="-40%"
          r="63.894028595458366%"
          stroke="#dddddd"
          stroke-opacity="1"
          data-center-top="@r: 50%;"
          data-top-bottom="@r: 70%;"
          class="aos-init aos-animate skrollable skrollable-between"
        ></circle>
        <circle
          data-aos="fade-up"
          data-aos-delay="400"
          cx="85%"
          cy="125%"
          r="82.93342579750347%"
          stroke="#dddddd"
          stroke-opacity="1"
          data-center-top="@r: 75%;"
          data-top-bottom="@r: 95%;"
          class="aos-init aos-animate skrollable skrollable-between"
        ></circle>
        <circle
          data-aos="fade-up"
          data-aos-delay="600"
          cx="-0.6211812627291273%"
          cy="125%"
          r="50%"
          stroke="#dddddd"
          stroke-opacity="1"
          data-center-top="@cx: -25%;"
          data-top-bottom="@cx: 45%;"
          class="aos-init aos-animate skrollable skrollable-between"
        ></circle>
      </svg>
    </figure>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        aria-label="Application progress"
        [ngStyle]="{ width: step * 25 + '%' }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</nav>
