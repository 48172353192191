import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notifications.service';
import { UNAUTHORIZED_MESSAGE, AUTH_EXPIRED_MESSAGE } from '../shared/globals';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any) {
    const notifier = this.injector.get(NotificationService);
    const authService = this.injector.get(MsalService);

    let message;
    switch (err.constructor) {
      case HttpErrorResponse: {
        message = err.message;
        console.warn('HTTP Error!');
        break;
      }
      case TypeError: {
        message = err.message;
        console.warn('Type Error!');
        break;
      }
      case Error: {
        // customize message for 401, 403, Invalid Identity Server URL
        message = err.message.match(
          /(401|403|could not find matching config for state)/g
        )
          ? UNAUTHORIZED_MESSAGE
          : err.message;

        console.warn('General Error!');
        authService.logoutRedirect({
          idTokenHint: authService.instance.getActiveAccount()?.idToken ?? '',
        });

        break;
      }
      default: {
        if (err.title) {
          if (err.detail) {
            message = err.detail;
          } else if (err.errors) {
            let paramName = Object.keys(err.errors)[0];
            let detail = err.errors[paramName][0];
            message = `Parameter '${paramName}' is invalid. ${detail}`;
          } else {
            message = [401].includes(err.status)
              ? AUTH_EXPIRED_MESSAGE
              : err.title;
          }
          console.warn(message, err);
        } else if (err.error) {
          // temporaly until there are endpoints
          // that return ModelState.BadRequestByMessage(e.Message);
          message = err.error[0];
          console.warn(message, err);
        } else {
          message = err;
          console.error('Something went wrong!', err);

          // not showing error toast for AADB2C90091: The user has cancelled entering self-asserted information.
          // since it's not an error created by the user but is an unhandled error from azure b2c side
          if (err.includes('AADB2C90091')) return;
        }
        break;
      }
    }
    notifier.showError(message);
    console.error(err);
  }
  /*

  // Find the underlying error in the given Wrapped error.
  private findOriginalError( error: any ) : any {
    while ( error && error.originalError ) {
      error = error.originalError;
    }
    return( error );
  }
*/
}
