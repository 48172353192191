import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-guideline-modal',
  templateUrl: './guideline-modal.component.html',
  styleUrls: ['./guideline-modal.component.scss']
})
export class GuidelineModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
